import React from 'react';
import styled from 'styled-components';
import Title from '../Title';

const Contact = ({ className }) => {
  return (
    <section className={className}>
      <Title title="contact" subtitle="us" />
      <form
        className="form"
        action="https://formspree.io/contact@effectualbusinessmedia.com"
        method="POST"
      >
        <div>
          <label htmlFor="name">name</label>
          <input type="text" name="name" id="name" className="formControl" />
        </div>
        <div>
          <label htmlFor="email">email</label>
          <input type="email" name="email" id="email" className="formControl" />
        </div>
        <div>
          <label htmlFor="message">message</label>
          <textarea
            name="message"
            id="message"
            rows="10"
            className="formControl"
          />
        </div>

        <input type="submit" value="submit" className="btn-primary" />
      </form>
    </section>
  );
};

export default styled(Contact)`
  padding: 2rem 0 4rem 0;
  background: var(--mainEarth);
  color: white;

  label {
    text-transform: capitalize;
    display: block;
    margin-bottom: 0.5rem;
  }

  form {
    width: 80vw;
    margin: 0 auto;
  }

  .formControl {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 0.375rem 0.75rem;
    color: white;
    border: 2px solid var(--darkestEarth);
    background-color: var(--darkEarth);
    border-radius: 0.25rem;
  }

  @media (min-width: 992px) {
    form {
      width: 50vw;
      margin: 0 auto;
    }
  }
`;
