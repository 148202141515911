export default [
  {
    path: '/about',
    name: 'about',
  },
  {
    path: '/lore',
    name: 'lore',
  },
  {
    path: '/contact',
    name: 'contact',
  },
];
