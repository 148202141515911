import React from 'react';
import { FaTwitter, FaYoutube } from 'react-icons/fa';

export default [
  {
    icon: <FaYoutube />,
    url: 'https://www.youtube.com',
  },
  {
    icon: <FaTwitter />,
    url: 'https://twitter.com/TCodesmith',
  },
];
