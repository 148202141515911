import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import links from '../constants/footer-links';
import socialIcons from '../constants/social-icons';
import styles from '../css/footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {links.map((item, index) => {
          return (
            <AniLink fade to={item.path} key={index}>
              {item.name}
            </AniLink>
          );
        })}
        <a
          href="https://www.iubenda.com/privacy-policy/39624705"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
      </div>
      <div className={styles.icons}>
        {socialIcons.map((item, idx) => {
          return (
            <a
              key={idx}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          );
        })}
      </div>
      <div className={styles.copyright}>
        {' '}
        Copyright &copy; {new Date().getFullYear()} Thecodesmith.co
      </div>
    </footer>
  );
};

export default Footer;
