import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import name from '../images/name.svg';
import links from '../constants/links';
import socialIcons from '../constants/social-icons';
import styles from '../css/navbar.module.css';

const Navbar = () => {
  const [isNavbarVisible, setNavbarVisibility] = useState(false);

  const toggleNavbar = () => {
    setNavbarVisibility(isNavbarVisible => !isNavbarVisible);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <AniLink fade to="/">
            <img className={styles.navImg} src={name} alt="codesmith logo" />
          </AniLink>
          <button
            type="button"
            className={styles.logoBtn}
            onClick={toggleNavbar}
          >
            <FaBars className={styles.logoIcon}></FaBars>
          </button>
        </div>

        <ul
          className={
            isNavbarVisible
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((item, idx) => {
            return (
              <li key={idx}>
                <AniLink fade to={item.path}>
                  {item.name}
                </AniLink>
              </li>
            );
          })}
        </ul>
        <div className={styles.navSocialLinks}>
          {socialIcons.map((item, idx) => {
            return (
              <a
                key={idx}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </a>
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
