import React from 'react';
import styled from 'styled-components';

const Title = ({ title, className, padding, fontSize }) => {
  return (
    <div className={className} padding={padding} fontSize={fontSize}>
      <h1>{title}</h1>
    </div>
  );
};

Title.defaultProps = {
  padding: '1.5rem 0 1.5rem 0',
  fontSize: '1.2rem',
};

export default styled(Title)`
  padding: ${props => props.padding};
  text-transform: uppercase;
  max-width: 90%;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-size: ${props => props.fontSize};
    letter-spacing: 7px;
    color: var(--mainWhite);
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    max-width: 75%;

    .post,
    .published {
      font-size: 1.35rem;
    }
  }
`;
