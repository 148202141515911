import React from 'react';
import SEO from '../components/seo';
import Contact from '../components/Contact/Contact';
import Layout from '../components/Layout';

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" keywords={[`thecodesmith`, `contact`]} />
      <Contact></Contact>
    </Layout>
  );
};

export default ContactPage;
